<template>
    <el-aside :class="['project-menu', classes.root]"
              width="65">
        <div class="f-col h-full hidden-x">
            <!-- Header -->
            <div class="w-20 f-y-center space-x-0.65 _px-0.65 border-bottom-1 border-gray-300 border-box">
                <el-button v-if="moreInfo"
                           type="text" 
                           icon="el-icon-back"
                           class="fs-1 p-0 text-gray-700"
                           @click="handleChangeMoreInfo" />
                <p class="text-el-dark grow">
                    {{ labels.header }}
                </p>
                <el-button v-if="!moreInfo"
                           type="text"
                           icon="el-icon-info"
                           class="fs-1 p-0 text-gray-700" 
                           @click="handleChangeMoreInfo" />
            </div>

            <!-- Structure -->
            <structure-panel v-if="!moreInfo"
                             class="w-20 _px-0.5 border-box" />

            <!-- Collapse -->
            <el-collapse v-else
                         v-model="tabActive" 
                         accordion
                         class="f-col w-20 hidden-y">
                <el-collapse-item v-for="tab in tabs"
                                  :key="tab.panel"
                                  :name="tab.panel">
                    <!-- Collapse Title -->
                    <template #title>
                        <div class="f-y-center space-x-0.75 _px-0.75">
                            <i :class="tab.icon"
                               class="fs-1" />
                            <span>{{ tab.label }}</span>
                        </div>
                    </template>

                    <!-- Collapse Panel -->
                    <access v-if="tab.permissions"
                            :permissions="tab.permissions">
                        <component :is="tab.panel" />
                    </access>
                    <component :is="tab.panel" 
                               v-else />
                </el-collapse-item>
            </el-collapse>
        </div>

        <!-- Toggle -->
        <div :class="classes.toggle.main"
             @click="toggle">
            <icon name="arrow-panel"
                  color="white"
                  :class="classes.toggle.icon" />
        </div>
    </el-aside>
</template>
<script>
import {mapGetters, mapState} from 'vuex';
import Access from '@/components/shared/Access';
import { planTypes } from '@/models/plans';

export default {
  name: 'Navigator',
  components: {
    ImageHistoryPanel: () => import( '@/components/asidepanels/panels/ImageHistory'),
    ImageClassesPanel: () => import( '@/components/asidepanels/panels/ImageClasses'),
    ImageMetaPanel: () => import( '@/components/asidepanels/panels/ImageMeta'),
    ClassesPanel: () => import( '@/components/asidepanels/panels/Classes'),
    HelpPanel: () => import( '@/components/asidepanels/panels/Help'),
    StructurePanel: () => import( '@/components/asidepanels/panels/Structure'),
    RoomsPanel: () => import( '@/components/asidepanels/panels/Rooms'),
    TechRoomsPanel: () => import( '@/components/asidepanels/panels/TechRooms'),
    WorkTypesPanel: () => import( '@/components/asidepanels/panels/WorkTypes'),
    Access
  },
  mixins: [],
  props: {
    filter: { type: Object, default: () => ({}) },
    filterForServer: { type: Object, default: () => ({}) },
    filterReady: { type: Boolean, defaul: false }
  },
  data () {
    return {
      moreInfo: false,
      tabActive: null,
      collapsed: get('structure-menu-collapsed', false, { cookie: true }) === 'true'
    }
  },
  computed: {
    ...mapGetters({navigator: 'navigator/navigator'}),
    ...mapState('project', ['selectedPlan']),

    tabs() {
      return [
        { panel: 'image-meta-panel', label: this.labels.tabs.imageMeta, icon: 'el-icon-camera', conditions: this.navigator.bar_photo },
        { panel: 'image-classes-panel', label: this.labels.tabs.imageClasses, icon: 'el-icon-edit-outline', conditions: this.navigator.bar_photo },
        { panel: 'image-history-panel', label: this.labels.tabs.imageHistory, icon: 'el-icon-s-order', conditions: this.navigator.bar_photo },
        { panel: 'classes-panel', label: this.labels.tabs.classes, icon: 'el-icon-s-flag', permissions: 'project_images_marking', conditions: this.navigator.bar_classes },
        { panel: 'work-types-panel', label: this.labels.tabs.workTypes, icon: 'el-icon-s-cooperation', permissions: 'project_work_type_edit'},
        { panel: 'help-panel', label: this.labels.tabs.help, icon: 'el-icon-question' },
        { panel: 'rooms-panel', label: this.labels.tabs.rooms, icon: 'el-icon-question', conditions: this.canShowMarkingRoom },
        { panel: 'tech-rooms-panel', label: this.labels.tabs.techRooms, icon: 'el-icon-question', permissions: 'project_structure_edit', conditions: this.canShowMarkingTechRoom }
      ].filter(x => x.conditions === undefined || x.conditions)
    },

    labels() {
      return {
        header: this.moreInfo ? 'Вернуться к структуре' : 'Структура помещений',
        tabs: {
          imageMeta: 'МЕТА',
          imageClasses: 'Разметка',
          imageHistory: 'История',
          classes: 'Классы',
          workTypes: 'Тип работ',
          help: 'Помощь',
          rooms: 'Помещения',
          techRooms: 'Разметка плана'
        }
      }
    },

    classes() {
      return {
        root: {
          'rel overflow-visible bg-white': true,
          'w-0 transition-all duration-500': this.collapsed,
          'w-20 border-right-3 border-gray-300 content-box transition-all duration-500': !this.collapsed
        },
        toggle: {
          main: {
            'l-full depth-100 w-0.75 h-4 rounded-right-xl border-right-1 border-top-1 border-bottom-1 border-gray-300 f-y-center f-x-start bg-white cursor-pointer': true,
            'abs top-collapse': this.collapsed,
            'abs-y-center': !this.collapsed
          },
          icon: {
            'w-0.5 h-1.5 stroke-el-dark': true,
            'rotate-180': !this.collapsed
          }
        }
      }
    },

    canShowMarkingRoom() {
      return this.selectedPlan.plan_type === planTypes.Tech || this.selectedPlan.type === planTypes.Tech
    },

    canShowMarkingTechRoom() {
      return this.selectedPlan.plan_type === planTypes.Common || this.selectedPlan.type === planTypes.Common
    }
  },
  methods: {
    toggle() {
      set('structure-menu-collapsed', this.collapsed = !this.collapsed, { cookie: true })
    },

    handleChangeMoreInfo() {
      this.moreInfo = !this.moreInfo
    }
  }
};
</script>

<style lang="scss">
.project-menu {
  .top-collapse {
    top: calc(50% + 2rem);
  }

  .el-collapse-item.is-active {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: hidden;

    .el-collapse-item__header {
      border-bottom-color: #EBEEF5; 
    }

    .el-collapse-item__wrap {
      display: flex;
      max-height: 100%;

      .el-collapse-item__content {
        width: 100%;
      }
    }
  }
}
</style>
