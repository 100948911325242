<template>
    <div class="f-col abs t-3.5 r-0.75 b-0 depth-10 w-22 space-y-1 scroll-y without-scrollbar border-box">
        <item :feature="selectedFeature"
              :removable="isRemovable(selectedFeature)"
              :creatable="creatable"
              @remove="removeFeature" />

        <item v-for="feature in features"
              :key="feature.get('id')"
              :feature="feature"
              :collapsed="true"
              :removable="isRemovable(feature)"
              :creatable="creatable"
              @remove="removeFeature"
              @select-feature="selectFeature" />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import { isAcceptance } from '@/models/shapes'

import Item from '@/components/map/FloorMapAcceptanceViewerItem.vue'

export default {
  name: 'FloorMapAcceptanceViewer',
  components: {
    Item
  },
  props: {
    selectedFeature: {
      type: Object,
      default: () => null
    },
    hittedFeatures: {
      type: Array,
      default: () => []
    },
    task: {
      type: Object,
      default: () => {}
    },
    taskPolygonsById: {
      type: Object,
      default: () => {}
    },
    editable: {
      type: Boolean,
      default: false
    },
    creatable: { type: Boolean, default: false }
  },
  computed: {
    features() {
      return this.hittedFeatures.filter(isAcceptance)
    }
  },
  methods: {
    ...mapActions('viewer/plan', ['toggleFeatures', 'setHittedFeatures']),

    isRemovable(feature) {
      return this.editable && (!feature.get('id') || !!this.taskPolygonsById[feature.get('id')])
    },

    removeFeature(feature) {
      this.$emit('remove', feature)
    },

    selectFeature(feature) {
      this.toggleFeatures([feature])
      this.setHittedFeatures([this.selectedFeature, ...this.hittedFeatures].filter(x => x != feature))
    }
  }
}
</script>
