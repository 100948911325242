<template>
    <el-container v-if="projectLoaded && accessLoadedForProject"
                  v-loading="accessLoading"
                  style="height: 100%;">
        <navigator v-if="showAsidePanel"
                   ref="navigator"
                   :filter="filter"
                   :filter-for-server="filterForServer"
                   :filter-ready="filterReady"
                   @filter-change="changeFilter"
                   @filter-confirm="confirmFilter" />
        <transition v-if="filterReady"
                    name="fade"
                    mode="out-in">
            <router-view ref="child"
                         :filter="filter"
                         :filter-for-server="filterForServer"
                         :filter-ready="filterReady"
                         @filter-confirm="confirmFilter"
                         @expanded="collapseNavigator" />
        </transition>
    </el-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { resourceable } from '@/store/connectors'
import { endOfDay, previousMonday, startOfDay, subMonths, subWeeks } from 'date-fns'

import { then } from '@/utils/immutable'
import { formatForServer, parse } from '@/utils/date'
import socketEvents from '@/utils/socketEvents'

import { houseTypeByStructure } from '@/models/structure'

import Navigator from '@/components/asidepanels/Navigator.vue'

export default {
  name: 'Project',
  components: { Navigator },
  mixins: [
    resourceable({ on: 'projects', name: 'projectOptions' })
  ],
  data() {
    const filter = {
      'jobTypes.id': this.$route.params.jobs || [],
      'house_type': houseTypeByStructure(this.$route.params.structure),
      'recognitions_count': [
        then(this.$route.query.from, x => parse(x, { withTime: false })) || previousMonday(previousMonday(new Date())),
        then(this.$route.query.to, x => parse(x, { withTime: false })) || new Date()
      ]
    }

    return {
      filter,
      filterForServer: this.prepareFilter(filter),
      filterReady: false
    }
  },
  computed: {
    ...mapGetters({
      showSystemNotifications: 'auth/showSystemNotifications'
    }),
    ...mapGetters('project', ['projectLoaded']),
    ...mapGetters('account', ['accessLoading', 'accessLoadedForProject']),

    showAsidePanel() {
      const exclusions = [
        'project.home',
        'project.notifications.lcr',
        'project.notifications.dpw',

        'project.tasks',
        'project.task',
        'project.calendar',
        'project.heatmap.v2',
        'project.work-schedule',
        'project.gpr',
        'project.document',
        'project.approval',
        'project.approval.info',

        'project.about',
        'project.about.common',
        'project.about.users',
        'project.about.activity',

        'project.jobs.progress',
        'project.work.schedule.mock',
        'settings.profile',
        'settings.common',
        'settings.structure',
        'settings.organizations',
        'settings.users',
        'settings.roles',
        'settings.bim',
        'settings.ast',
        'settings.licenses',
        'settings.neural',
        'settings.notifications'
      ];

      return !exclusions.some(name => this.$route.name.includes(name));
    },
    projectChannelName() {
      return `v1.projects.${this.$route.params.projectId}`;
    },

    houseType() {
      return houseTypeByStructure(this.$route.params.structure)
    }
  },
  watch: {
    projectOptions: {
      handler({ project_structure_default_filter: { value } = {} } = {}) {
        const { from, to } = this.$route.query || {}
        const on = !from && !to && value
        const now = new Date()

        on && (() => {
          const filter = this.filter = {
            ...this.filter,
            recognitions_count: {
              'last_month': [subMonths(now, 1), now],
              'last_two_weeks': [subWeeks(now, 2), now],
              'last_week': [subWeeks(now, 1), now],
              'no_default': []
            }[value]
          }

          this.filterForServer = this.prepareFilter(filter)
          this.filterReady = true
        })()
      },
      immediate: true
    },
    houseType() {
      this.fetchProject({ id: this.$route.params.projectId, houseType: this.houseType })
      this.changeFilter({ ...this.filter, house_type: this.houseType })
    }
  },
  created() {
    this.closeProject();
    this.loadStructure();
    this.listenProjectEventsNew();
  },
  async mounted() {
    const id = this.$route.params.projectId

    this.sentUserActivity({
      slug: 'project',
      type: 'opened',
      subject_id: id
    })

    await this.fetchProject({ id, houseType: this.houseType })
    await this.fetchAccess({ type: 'project' })

    this.fetchProjectOptions()
  },
  beforeDestroy() {
    this.leaveProjectChannel()
  },
  destroyed() {
    this.closeProject()
    this.clearProjectAccess()
    this.clearTasks()
  },
  methods: {
    ...mapActions('activity', ['sentUserActivity']),
    ...mapActions('project', ['fetchProject']),
    ...mapActions('account', ['fetchAccess', 'clearProjectAccess']),
    ...mapActions('tasks', ['clearTasks']),

    changeFilter(x) {
      this.filter = x
      this.filterForServer = this.prepareFilter(x)
      this.$refs?.navigator?.applyFilterChange?.(this.filterForServer)
    },

    confirmFilter(x) {
      this.filter = x
      this.filterForServer = this.prepareFilter(x)
      this.$refs?.navigator?.applyFilterConfirm?.(this.filterForServer)
      this.$refs?.child?.applyFilter?.(this.filterForServer)
    },

    prepareFilter(x) {
      const r = { ...x }

      const from = then(r.recognitions_count?.[0], x => formatForServer(startOfDay(x, { zoned: false })))
      const to = then(r.recognitions_count?.[1], x => formatForServer(endOfDay(x, { zoned: false })))
          
      r['image.shot_at_from'] = from
      r['image.shot_at_to'] = to
      r['belongsToProject'] = this.$route.params.projectId

      delete r['recognitions_count']

      return r
    },

    goBack() {
      switch (this.$route.name) {
      default:
        this.$router.go(-1);
      }
    },

    collapseNavigator() {
      this.$refs.navigator?.collapse?.()
    },

    ...mapActions({
      closeProject: 'project/clearProject',
      getProjectStatisticsAfterStructure: 'project/getProjectStatisticsAfterStructure',
      clearNavBar: 'navigator/clear',
      updateNavBar: 'navigator/update'
    }),

    loadStructure() {
      this.clearNavBar();
      // this.getProjectStatisticsAfterStructure({projectId:this.$route.params.projectId});
      this.updateNavBar({ui_project: true});
    },
    listenProjectEventsNew() {
      socketEvents.forEach(eventName => {
        this.$socket.echoClient.private(this.projectChannelName).listen(eventName, (event) => {
          let eventNameFormatted = this.eventNameSplitter(eventName);
          switch (eventNameFormatted) {
          case 'houses':
          case 'floors':
          case 'floor-plans':
            this.loadStructure();
            break;
          default:
            return;
          }
        });
      });
    },


    leaveProjectChannel() {
      this.$socket.echoClient.leave(this.projectChannelName);
    }
  }
};
</script>
<style lang="scss">
.ol-map {
  height: 100%;
  width: 100%;
}

.el-drawer__container {
  overflow: hidden;
}

.no-padding {
  padding: 0 !important;
}

.no-scroll {
  overflow: hidden !important;
}
</style>
