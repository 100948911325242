<template>
    <div :class="classes.root">
        <!-- Filters -->
        <filter-popover v-if="!uiHide"
                        popper-class="floor-map-filter__container border-box _px-1.5 _py-1"
                        width="600"
                        popover-placement="bottom-start"
                        :value="value"
                        :initial="initial"
                        :fields="fields"
                        :close-on-accept="false"
                        :no-fields-description="labels.noFields"
                        with-group-label
                        :count="0"
                        @change="change"
                        @confirm="confirm">
            <template slot="button">
                <plan-button el-icon="el-icon-s-operation text-white"
                             label="Фильтры"
                             class="w-7">
                    <span class="_ml-0.25 fs-0.75 font-bold text-accent">({{ count }})</span>
                </plan-button>
            </template>
        </filter-popover>

        <!-- Layers -->
        <div class="f space-x-0.5">
            <plan-button v-for="field in layersFields"
                         :key="field.key"
                         :label="field.label"
                         :active="field.active"
                         @click="field.click" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { resourceable, actionable } from '@/store/connectors'

import { defineFilterField } from '@/utils/ui'

import { planTypes } from '@/models/plans'

import FilterPopover from '@/components/popovers/FilterPopover'
import PlanButton from '@/components/map/PlanButton/PlanButton'

const labels = {
  title: 'Фильтры',
  // layers: 'Слои',
  // additions: 'Дополнительно',

  group: {
    image: 'Съемка',
    task: 'Приемка'
  },

  image_shot: 'Дата съемки',

  withTechRooms: 'Разметка',
  withCamera: 'Камера 360',
  withDefect: 'Дефекты',
  withPolygons: 'Приемка',

  withQopter: 'Квадрокоптер',
  withFacade: 'Фасад',
  withPhoto: '2D снимок',
  withFinishedDefect: 'Закрытые дефекты',

  byDefects: 'Только дефекты',
  byFilterPassedOnly: 'По фильтру проекта',
  with_finished_tasks: 'Завершенные работы',
  with_finished_not_accepted_tasks: 'Непринятые работы',

  polygons: 'Разметка',
  withInspector: 'С ответственным',
  withoutInspector: 'Без ответственного',
  withWorkType: 'Вид работ указан',
  withoutWorkType: 'Вид работ не указан',
  withAcceptable: 'Только объекты текущей приемки',

  task_created: 'Период создания приемки',
  task_created_from: 'Приемка работ создана с',
  task_created_to: 'Приемка работ создана по',
  task_booked_schedule: 'Период проведения приемки',
  task_booked_schedule_from: 'Приемка работ проводилась с',
  task_booked_schedule_to: 'Приемка работ проводилась по',
  task_text_search: 'Поиск по ключевым слова',
  task_job_type_id: 'Виды работ',

  contractor: 'Подрядчик',
  engineer: 'Инженер строительного контроля',
  inspector: 'Ответственный',

  noFields: 'Нет условий фильтрации'
}

// const tooltips = {
//   withCamera: 'Отобразить точки 360 съемки',
//   withQopter: 'Отобразить точки направления съемки с квадрокоптера',
//   withFacade: 'Отобразить точки направления съемки фасада',
//   withPhoto: 'Отобразить точки съемки с фотографией дефекта',
//   withDefect: 'Отобразить точки съемки имеющие активные дефекты',
//   withFinishedDefect: 'Отобразить точки съемки не имеющие активных дефектов',
//   withPolygons: 'Отобразить области разметки принимаемых работ',

//   byDefects: 'Отобразить только точки съемки имеющие дефекты',
//   byFilterPassedOnly: 'Отобразить точки съемки в соответствии с фильтром проекта',
//   with_finished_tasks: 'Отобразить области разметки завершенных работ',
//   with_finished_not_accepted_tasks: 'Отобразить области разметки непринятых работ',

//   withInspector: 'Ответственный назначен',
//   withoutInspector: 'Ответственный не назначен',
//   withWorkType: 'Вид работ указан',
//   withoutWorkType: 'Вид работ не указан',
//   withAcceptable: 'Отобразить только объекты текущей приемки'
// }

// const additions = [
//   'byFilterPassedOnly',
//   'byDefects',
//   'with_finished_tasks',
//   'with_finished_not_accepted_tasks'
// ]

const additionsForWorkPolygons = [
  'withInspector',
  'withoutInspector',
  'withWorkType',
  'withoutWorkType'
]

const additionsForWorkPolygonsAtAcceptance = [
  'withAcceptable'
]

export default {
  components: {
    FilterPopover,
    PlanButton
  },

  mixins: [
    resourceable({ on: 'dirsRevision', name: 'workTypes', mounted: true }),
    resourceable({ on: 'dirsRevision', name: 'jobs', mounted: true }),
    actionable({ on: 'floorPlans', name: 'fetchWorkLayerPolygons', loadable: true })
  ],

  props: { 
    uiHide: { type: Boolean, default: false },
    planType: { type: String, default: 'common' },
    selectedLayer: { type: Object, default: null },
    value: { type: Object, default: null },
    initial: { type: Object, default: () => ({}) },
    acceptance: { type: String, default: null }
  },

  data() {
    return {
      polygons: [],

      labels
    }
  },

  computed: {
    ...mapGetters('viewer/plan', ['isTransformation']),
    ...mapGetters('account', ['hasAccess']),

    classes() {
      return {
        root: {
          'floor-map-filter abs f-x-between': true,
          '-t-3 r-0': this.uiHide,
          't-0.75 l-3.5 r-0.75': !this.uiHide
        }
      }
    },

    count() {
      return Object.keys(this.value).filter(key => [
        'inspector',
        'inspectorOrganization',

        ...(this.planTypeCommon || this.planTypeTech) && [
          'recognitions_count',
          'task_text_search',
          'contractor',
          'contractorOrganization',
          'task_job_type_id',
          'worker',
          'workerOrganization',
          'task_created_from',
          'task_booked_schedule_from',
          'with_finished_tasks'
        ] || [],

        ...(this.planTypeWork) && [
          'polygonIds',
          'workTypes',
          'withInspector',
          'withoutInspector',
          'withWorkType',
          'withoutWorkType',
          'workStatus'
        ] || [],
        
        ...(this.planTypeWork && this.acceptance) && ['withAcceptable'] || []
      
      ].includes(key) && (Array.isArray(this.value[key]) ? this.value[key].length : !!this.value[key])).length
    },

    layers() {
      return [
        this.planTypeCommon && 'withTechRooms',
        ...(this.planTypeCommon || this.planTypeTech) && [
          'withCamera'
        ] || [],
        ...(!this.uiHide && (this.planTypeCommon || this.planTypeTech)) && [
          'withDefect',
          'withPolygons'
        ] || []
      ].filter(is)
    },

    layersFields() {
      return this.layers.map(layer => ({
        key: layer,
        label: labels[layer],
        active: this.value[layer],
        click: () => {
          const newFilterValue = { ...this.value, [layer]: !this.value[layer] }

          this.change(newFilterValue)
          this.confirm(newFilterValue)
        }
      }))
    },

    fields() {
      return [
        (!this.planTypeWork) && defineFilterField({
          group: labels.group.image,
          type: 'daterange',
          label: labels.image_shot,
          from: value => value['recognitions_count'],
          to: value => ({ 'recognitions_count': value })
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'input',
          key: 'task_text_search',
          label: labels.task_text_search
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'user',
          label: labels['contractor'],
          props: {
            isContractor: true,
            asKey: false,
            withOrganizations: true
          },
          from: value => value['contractor'] || value['contractorOrganization'],
          to: value => ({
            'contractor': value?._isUser && value,
            'contractorOrganization': value?._isOrganization && value
          })
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'multiselect',
          key: 'task_job_type_id',
          placeholder: 'Выбрать',
          label: labels.task_job_type_id,
          loading: this.jobsLoading,
          options: this.jobs.map(({ id, code, name }) => ({ value: id, label: code + ' ' + name }))
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'user',
          label: labels['engineer'],
          props: {
            isEngineer: true,
            asKey: false,
            withOrganizations: true
          },
          from: value => value['worker'] || value['workerOrganization'],
          to: value => ({
            'worker': value?._isUser && value,
            'workerOrganization': value?._isOrganization && value
          })
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'daterange',
          label: labels['task_created'],
          from: value => ([ value['task_created_from'], value['task_created_to'] ]),
          to: value => ({
            'task_created_from': value[0],
            'task_created_to': value[1]
          })
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'daterange',
          label: labels['task_booked_schedule'],
          from: value => ([ value['task_booked_schedule_from'], value['task_booked_schedule_to'] ]),
          to: value => ({
            'task_booked_schedule_from': value[0],
            'task_booked_schedule_to': value[1]
          })
        }),

        (!this.planTypeWork) && defineFilterField({
          group: labels.group.task,
          type: 'switch-new',
          label: labels['with_finished_tasks'],
          from: value => value['with_finished_tasks'],
          to: value => ({ 'with_finished_tasks': value })
        }),


        (this.planTypeWork
          && this.hasAccess({ permissions: permissionsAny(
            'project_floor_work_plan_be_administrator', 
            'project_floor_work_plan_be_supervisor',
            'project_floor_work_plan_be_foreman'
          ) })
        ) && defineFilterField({
          group: labels.group.task,
          type: 'multiselect',
          key: 'polygonIds',
          placeholder: 'Выбрать',
          label: labels.polygons,
          loading: this.fetchWorkLayerPolygonsLoading,
          options: this.polygons.map(({ id }, i) => ({ value: id, label: `Полигон ${i + 1}` }))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: permissionsAny(
            'project_floor_work_plan_be_supervisor', 
            'project_floor_work_plan_be_foreman',
            'project_tasks_be_becomes_constructor', 
            'project_tasks_be_construction_control_engineer', 
            'project_tasks_be_general_control_engineer'
          ) })
        ) && defineFilterField({
          group: labels.group.task,
          type: 'multiselect',
          key: 'workTypes',
          placeholder: 'Выбрать',
          label: labels.task_job_type_id,
          loading: this.workTypesLoading,
          options: this.workTypes.map(({ id, unified_code, name }) => ({ value: id, label: [unified_code, name].filter(is).join(' ') }))
        }),

        (this.planTypeWork 
          && this.hasAccess({ permissions: ['project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman'] })
        ) && defineFilterField({
          group: labels.group.task,
          type: 'user',
          label: labels.inspector,
          props: {
            isWorkPlanForeman: true,
            withOrganizations: true
          },
          from: value => value['inspector'] || value['inspectorOrganization'],
          to: value => ({
            'inspector': value?._isUser && value,
            'inspectorOrganization': value?._isOrganization && value
          })
        }),

        ...(this.planTypeWork && this.hasAccess({ permissions: ['project_floor_work_plan_be_supervisor'] })) && 
          additionsForWorkPolygons.map(x => defineFilterField({
            group: labels.group.task,
            type: 'switch-new',
            label: labels[x],
            from: value => value[x],
            to: value => ({ [x]: value })
          })) || [],

        ...(this.planTypeWork && this.acceptance && this.hasAccess({ 
          permissions: permissionsAny(
            'project_tasks_be_becomes_constructor', 
            'project_tasks_be_construction_control_engineer', 
            'project_tasks_be_general_control_engineer'
          ) 
        })) && additionsForWorkPolygonsAtAcceptance.map(x => defineFilterField({
          group: labels.group.task,
          type: 'switch-new',
          label: labels[x],
          from: value => value[x],
          to: value => ({ [x]: value })
        })) || [],

        (this.planTypeWork 
          && this.hasAccess({ permissions: ['project_floor_work_plan_be_foreman'] })
        ) && defineFilterField({
          group: labels.group.task,
          type: 'polygon-work-status',
          key: 'workStatus',
          props: {
            asKey: true
          }
        })
      ].filter(is)
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    }
  },

  mounted() {
    this.selectedLayer 
      && this.fetchWorkLayerPolygons({ layer: this.selectedLayer, independent: true })
        .then(r => this.polygons = r)
  },

  methods: {
    onByDefects: function() {
      this.value.byDefects = !this.value.byDefects
      this.$emit('change', this.value)
    },

    onByExternalFilter: function() {
      this.value.byFilterPassedOnly = !this.value.byFilterPassedOnly
      this.$emit('change', this.value)
    },

    change(value) {
      this.$emit('change', value)
    },

    confirm(value) {
      this.$emit('confirm', value)
    }
  }
}
</script>

<style lang="scss">
.floor-map-filter {
  .el-button {
    &:hover {
      background-color: #409EFF;
    }

    &.is_active {
      color: #409EFF;
      background-color: #303133;
      
      span {
        color: #409EFF;
      }

      &:hover {
        color: #FFFFFF;
        background-color: #409EFF;

        span {
          color: #FFFFFF;
        }
      }
    }
  }

  &__container {
    .el-form {
      .cols-2 {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
  
      .el-form-item {
        &:not(.switch-new) {
          .el-form-item__label {
            display: inline;
            font-size: 0.75rem;
            color: #616161;
            font-weight: 600;
            padding: 0;
          }
        }
  
  
        &.switch-new {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          line-height: 2rem;
  
          .el-form-item__label {
            color: #303133;
            margin-left: 0.5rem;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>
