import { loadable, resourceable, actionable } from '@/store/mixins'
import { difference, then } from '@/utils/immutable'
import { fromError } from '@/utils/common'
import {labelAt} from '@/utils/date';

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'projectsForSelection',
      once: true,
      from: ({ api }) => api.projects.getProjects({
        params: {
          include: ['avatar'],
          fields: {
            avatar: [
              'id',
              'imageable_type',
              'imageable_id',
              'storage_thumb_url'
            ]
          },
          page: {
            size: 0
          },
          sort: '-updated_at'
        }
      }).then(r => r || [])
    }),

    resourceable({
      name: 'organizationProjects',
      from: ({ api }, { organization }) => api.projectsV2.getOrganizationProjects({ organization })
        .then(r => r.data.data.data || [])
    }),

    resourceable({
      name: 'projectOptions',
      once: true,
      from: ({ api, getters }) => api.projects.getProjectOptions({ project: getters['project/project'] })
    }),

    resourceable({
      name: 'projectMeta',
      from: ({ api, getters }) => api.projects.getProjectDashboard(getters['project/project'].id).then(x => x.data.data || {})
    }),

    resourceable({
      name: 'projectPlaces',
      once: true,
      from: ({ api, getters }, { withFloors, withPlans, withWorkPlans, byWorkLayerType }) => api.projects.getProjectStructure({
        project: getters['project/project'],
        withWorkPlans,
        withWorkLayers: withWorkPlans,
        byWorkLayerType
      })
        .then(houses => houses.map(x => ({
          id: x.id,
          type: 'house',
          value: x.id,
          label: `${x.street} ${x.number ? x.number : '' } ${x.housing ? 'к'+x.housing : '' }`,
          icon: x.type,
          leaf: !withFloors,
          children: withFloors && (x.floors || []).map(x => ({
            id: x.id,
            type: 'floor',
            value: x.id,
            label: x.number,
            leaf: !withPlans,
            children: [
              ...then(withPlans, () => (x.floor_plans || []).map(x => ({
                id: x.id,
                type: 'plan',
                value: x.id,
                label: x.name || labelAt(x.created_at, { withTime: false, prefix: 'План от ' })
              }))) || [],
              ...then(withWorkPlans, () => (x.floor_work_plans || []).map(x => ({
                id: x.id,
                type: 'work_plan',
                value: x.id,
                label: x.name || labelAt(x.created_at, { withTime: false, prefix: 'План от ' }),
                layers: x.layers || []
              }))) || []
            ]
          }))
        })))
    }),

    resourceable({
      name: 'regions',
      from: ({ api }) => api.projects.getProjectRegions()
    }),
    resourceable({
      name: 'projectsTree',
      from: ({ api }) => api.projects.getProjectsTree()
    }),

    actionable({
      name: 'toggleOrganizationProjects',
      loadable: true,
      at: async ({ api }, { organization, enabledOrganizationProjects, selectedOrganizationProjects }) => {
        const enabled = difference(selectedOrganizationProjects, enabledOrganizationProjects, (a, b) => a.id === b.id)
        const disabled = difference(enabledOrganizationProjects, selectedOrganizationProjects, (a, b) => a.id === b.id)
        const reason = 'expired_license_agreement'

        await Promise.all(enabled.map(project => api.projectsV2.enableOrganizationProject({ organization, project })))
        await Promise.all(disabled.map(project => api.projectsV2.disableOrganizationProject({ organization, project, reason })))
      }
    }),

    actionable({
      name: 'unblockOrganization',
      loadable: true,
      at: ({ api }, { project, file }) => api.projectsV2.unblockOrganization({ project, file }).catch(fromError)
    }),

    actionable({
      name: 'clearProject',
      at: ({ state, dispatch }) => {
        state.projectHouses = null
        state.floorsByHouse = {}

        dispatch('dirsRevision/disposeJobs', null, { root: true })
        dispatch('dirsRevision/disposeTags', null, { root: true })
        dispatch('projects/disposeProjectPlaces', null, { root: true })
        dispatch('tasks/disposeLotDefects', null, { root: true })
      }
    }),

    actionable({
      name: 'storeOptions',
      at: ({ api, getters, state }, options) => api.projects.updateProjectOptions({ project: getters['project/project'], options })
        .then(r => state.projectOptions = r)
    }),

    loadable({ action: 'fetchNextProjects' }),
    loadable({ action: 'fetchProjects' })
  ],
  state: {
    projects: [],
    projectsMeta: null,
    projectsPosition: {
      number: 1,
      size: 20
    },
    bimAccount: {},
    projectLastGprVersion: {},

    projectHouses: null,
    floorsByHouse: {}
  },
  getters: {
    shouldRequirePWP: state => state.projectOptions['enable_project_work_production_in_acceptance_work_tasks'],

    projects: state => state.projects,
    bimAccount: state => state.bimAccount,
    projectsPosition: state => state.projectsPosition,
    hasMoreProjects: state => !state.projectsMeta || (state.projects?.length < state.projectsMeta.total),
    
    projectsMeta: state => state.projectsMeta,

    jobTypesFromLastGprVersion: state => {
      const {gprJobs} = state.projectLastGprVersion
      return gprJobs ? gprJobs.map(({dir_job_type}) => dir_job_type): []
    }
  },
  mutations: {
    SET_PROJECTS: (state, projects) => state.projects = projects,
    SET_PROJECTS_META: (state, value) => state.projectsMeta = value,

    APPEND_PROJECTS: (state, projects) => state.projects = [...state.projects, ...projects],
    NEXT_PAGE: state => state.projectsPosition.number++,

    CLEAR_PROJECTS: state => {
      state.projects = []

      state.projectsPosition = {
        number: 1,
        size: 20
      }

      state.projectsMeta = null

      state.fetchNextProjectsLoading = false
    },

    SET_BIM_ACCOUNT: (state, payload) => state.bimAccount = payload,
    SET_PROJECT_LAST_GPR_VERSION: (state, payload) => state.projectLastGprVersion = payload
  },
  actions: {
    fetchProjects: async function({ commit }, { id }) {
      const { data, meta } = await this.$api.projects.getProjects({
        params: {
          include: 'avatar',
          sort: '-updated_at',
          fields: {
            projects: [
              'id',
              'name',
              'address',
              'updated_at',
              'bim_url',
              'tableau_url'
            ],
            avatar: [
              'id',
              'imageable_type',
              'imageable_id',
              'storage_thumb_url'
            ]
          },
          filter: {
            ...id && { ids: id }
          },
          page: {
            number: 1,
            size: 500
          }
        }
      })

      commit('SET_PROJECTS', data)
      commit('SET_PROJECTS_META', meta)
    },

    fetchNextProjects: async function({ commit, dispatch, getters }, { id }) {
      const on = getters['hasMoreProjects']
      const position = getters['projectsPosition']

      const perform = async () => {
        const { data, meta } = await this.$api.projects.getProjects({
          params: {
            include: 'avatar',
            sort: '-updated_at',
            fields: {
              projects: [
                'id',
                'name',
                'address',
                'updated_at',
                'bim_url',
                'tableau_url'
              ],
              avatar: [
                'id',
                'imageable_type',
                'imageable_id',
                'storage_thumb_url'
              ]
            },
            filter: {
              ...id && { ids: id }
            },
            page: {
              number: 1,
              size: 500
            }
          }
        })

        commit('APPEND_PROJECTS', data)
        commit('SET_PROJECTS_META', meta)
        commit('NEXT_PAGE')
      }

      on && await perform()
    },

    clearProjects({ commit }) {
      commit('CLEAR_PROJECTS')
    },

    getBimAccount: async function ({commit}, project) {
      commit('SET_BIM_ACCOUNT', {})
      let {data} = await this.$api.projectsV2.getBimAccount(project)
      commit('SET_BIM_ACCOUNT', data.data)
      return data
    },
    getProjects: async function ({commit}, {payload} = {}) {
      let {data} = await this.$api.projects.getProjects(payload)
      commit('SET_PROJECTS', data)
      return data
    },
    getProject: async function (context, {
      projectId,
      payload
    }) {
      let {data} = await this.$api.projects.getProject(projectId, payload);
      return data;
    },
    storeProject: async function (context, {payload}) {
      let {data} = await this.$api.projects.storeProject(payload);
      return data;
    },

    getProjectDashboard: async function (context, projectId) {
      let {data} = await this.$api.projects.getProjectDashboard(projectId);
      return data;
    },

    getProjectGprLastVersion: async function ({commit}, projectId) {
      let {data} = await this.$api.projectsV2.getProjectGprLastVersion(projectId)
      commit('SET_PROJECT_LAST_GPR_VERSION', data.data)
      return data
    }
  }
};
