<template>
    <div :class="classes.root">
        <template v-if="!isProtocolMode && !isPremisesMode && !isAcceptanceMode">
            <!-- Hz -->
            <plan-button v-if="(planTypeCommon || planTypeTech) && selectedFeature && selectedFeature.getProperties().id && !isTransformation && !comparable"
                         permissions="project_structure_edit"
                         tooltip="Список планируемых объектов"
                         el-icon="el-icon-s-operation"
                         @click="$emit('handleOptionsMarker')" />

            <button-group v-if="!isTransformation && !comparable">
                <plan-button v-if="!planTypeWork"
                             permissions="project_comments_edit"
                             tooltip="Оставить комментарий"
                             icon="chat"
                             class="fill-white stroke-none"
                             @click="$emit('comment')" />
                
                <plan-button v-if="!planTypeWork && hasComments"
                             permissions="project_comments_show"
                             tooltip="Показать комментарии"
                             :active="isComments"
                             el-icon="el-icon-notebook-2"
                             @click="$emit('comment')" />

                <plan-button tooltip="Установить калибровку для линейки"
                             :active="isCalibration"
                             icon="ruler"
                             class="fill-white stroke-none"
                             @click="$emit('calibration')" />

                <plan-button tooltip="Рассчитать расстояние"
                             :active="isLength"
                             icon="length"
                             class="fill-white stroke-none"
                             @click="$emit('length')" />

                <plan-button tooltip="Рассчитать площадь"
                             :active="isArea"
                             icon="square"
                             class="fill-white stroke-none"
                             @click="$emit('area')" />

                <plan-button v-if="!planTypeWork"
                             tooltip="Указать север"
                             :active="isOrientation"
                             icon="compass"
                             class="fill-white stroke-none"
                             @click="$emit('orientation')" />

                <plan-button v-if="!planTypeWork"
                             tooltip="Удалить размеченные области"
                             icon="rubber"
                             class="fill-white stroke-none"
                             @click="$emit('clear')" />
            </button-group>

            <!-- Enable or confirm transformation -->
            <plan-button v-if="planTypeCommon && !isAcceptanceMode"
                         :tooltip="isTransformation ? 'Подтвердить' : 'Перемещение'"
                         :icon="isTransformation ? 'check' : 'move'"
                         class="fill-white stroke-white"
                         @click="toggleTransformation" />
            
            <!-- Transformation -->
            <template v-if="isTransformation">
                <!-- Cancel -->
                <plan-button icon="cross"
                             tooltip="Отменить"
                             class="stroke-white"
                             @click="cancelTransformation" />

                <!-- Context title -->
                <p class="h-2 lh-2 _m-0 _ml-0.5 _px-1 fs-0.9 bg-accent rounded text-white no-wrap">
                    Перемещение
                </p>
            </template>

            <!-- Points -->
            <div v-if="(planTypeCommon || planTypeTech) && isPointMode && !isTransformation && !comparable"
                 class="f space-x-0.25">
                <access v-if="!structureTypeFacades"
                        permissions="project_structure_edit"
                        hidable>
                    <el-dropdown trigger="click"
                                 @command="handleCommand">
                        <plan-button icon="plus"
                                     class="stroke-white"
                                     :active="isCamera || isDefect">
                            <span class="f-y-center text-white fs-0.8 _px-0.5">
                                Добавить точку<i class="el-icon-arrow-down el-icon--right" />
                            </span>
                        </plan-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="camera">
                                Камера 360
                            </el-dropdown-item>
                            <el-dropdown-item command="defect">
                                Дефект
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </access>

                <!-- Point :: Monitor -->
                <plan-button v-if="structureTypeFacades"
                             permissions="project_structure_edit"
                             tooltip="Добавить точку мониторинга"
                             :active="isMonitor"
                             icon="monitor"
                             class="fill-white"
                             @click="$emit('monitor', $event)" />
            </div>


            <template v-if="!isTransformation">
                <!-- Rooms -->
                <plan-button v-if="hasTechnicalPlan && isRoomMode && !comparable"
                             permissions="project_structure_edit"
                             tooltip="Добавить помещение"
                             el-icon="el-icon-edit-outline"
                             :active="isRoom"
                             @click="$emit('room')" />

                <!-- Tech Rooms -->
                <plan-button v-if="planTypeCommon && isTechRoomMode && !comparable"
                             permissions="project_structure_edit"
                             tooltip="Добавить разметку плана"
                             :active="isTechRoom"
                             el-icon="el-icon-edit-outline"
                             @click="$emit('tech-room')" />

                <!-- Tech Rooms List -->
                <plan-button v-if="planTypeCommon && !comparable"
                             permissions="project_structure_edit"
                             tooltip="Области разметки плана"
                             icon="rect-outlined-small"
                             class="stroke-white"
                             :active="visibleTechRoomList"
                             @click="$emit('tech-room-list')" />

                <button-group>
                    <!-- Save -->
                    <plan-button v-if="shouldSave"
                                 :permissions="permissions.save"
                                 label="Сохранить изменения"
                                 primary
                                 @click="$emit('save')" />

                    <plan-button v-if="hasSelectedPhoto"
                                 tooltip="Перейти к фотографии"
                                 el-icon="el-icon-picture"
                                 @click="$emit('photo')" />
                </button-group>
            </template>
        </template>

        <div v-if="isProtocolMode && !isTransformation"
             class="f space-x-0.5">
            <plan-button tooltip="Назад"
                         el-icon="el-icon-back"
                         @click="$emit('hideProtocols')" />
            
            <plan-button tooltip="Классы дефектов"
                         :active="defectClasses"
                         icon="defect-class"
                         @click="$emit('showDefectClasses')" />

            <plan-button tooltip="Показать реперные точки"
                         :active="isPointMode"
                         el-icon="el-icon-location"
                         @click="$emit('showPoints')" />
            
            <plan-button tooltip="Показать реперные точки"
                         :active="isPointMode"
                         el-icon="el-icon-edit"
                         @click="$emit('drawProtocol')">
                <el-row slot="tooltip">
                    <el-col class="text-size--supplementary">
                        Разметка дефекта
                    </el-col>
                    <el-col class="text-size--supplementary">
                        Разметьте область дефекта на плане с помощью ЛКМ
                    </el-col>
                </el-row>
            </plan-button>
            
            <plan-button tooltip="Удалить последнюю точку полигона (Esc)"
                         icon="rubber"
                         @click="$emit('clearVector')" />

            <plan-button label="Отмена"
                         @click="$emit('cancelAll')" />
        </div>

        <div v-if="isPremisesMode && !isTransformation"
             class="f space-x-0.5">
            <plan-button tooltip="Назад"
                         el-icon="el-icon-back"
                         @click="$emit('hide-premises')" />

            <button-group>
                <plan-button tooltip="Классы помещений"
                             :active="premisesClassesVisibility"
                             el-icon="el-icon-notebook-1"
                             @click="$emit('set-premises-classes-visibility')" />

                <plan-button tooltip="Показать реперные точки"
                             :active="isPointMode"
                             el-icon="el-icon-map-location"
                             @click="$emit('showPoints')" />

                <plan-button tooltip="Разметка помещения"
                             :active="isPremises"
                             icon="circle-dashed"
                             class="stroke-white"
                             @click="$emit('draw-premises')" />
            </button-group>

            <plan-button label="Отмена"
                         @click="$emit('cancelAll')" />
        </div>

        <!-- Acceptance -->
        <template v-if="isAcceptanceMode && !isTransformation && !planTypeWork && editable">
            <!-- Final acceptance -->
            <plan-button label="Окончательная приемка"
                         :active="isFinalAcceptance"
                         @click="$emit('draw-final-acceptance')" />

            <!-- Intermediate acceptance -->
            <plan-button v-if="false"
                         label="Промежуточная приемка"
                         :active="isIntermediateAcceptance"
                         @click="$emit('draw-intermediate-acceptance')" />

            <!-- Save acceptances -->
            <plan-button v-if="!contractable"
                         label="Сохранить"
                         el-icon="el-icon-check"
                         @click="$emit('confirm-acceptance')" />
        </template>

        <!-- Work plan -->
        <div v-if="planTypeWork && !isTransformation && editable"
             class="f space-x-0.5">
            <!-- Draw work polygon -->
            <plan-button v-if="!isAcceptanceMode"
                         tooltip="Добавить разметку"
                         permissions="project_floor_work_plan_be_administrator"
                         icon="rect-outlined"
                         :active="isWorkPolygon"
                         @click="$emit('work-polygon-draw')" />

            <!-- Compare with common plan -->
            <el-dropdown v-if="floorPlans.length"
                         trigger="click"
                         @command="$emit('work-plan-compare', $event)">
                <!-- Trigger -->
                <plan-button label="Фото 360" 
                             icon="compare" />

                <!-- List -->
                <el-dropdown-menu slot="dropdown"
                                  class="max-h-1/4 overflow-auto with-custom-scrollbar">
                    <el-dropdown-item v-for="plan in floorPlans"
                                      :key="plan.id"
                                      :command="plan">
                        {{ getPlanLabel(plan) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { planTypes, getPlanLabel } from '@/models/plans'
import { structureTypes } from '@/models/structure'

import ButtonGroup from '@/components/map/PlanButton/PlanButtonGroup'
import PlanButton from '@/components/map/PlanButton/PlanButton'

export default {
  components: {
    ButtonGroup,
    PlanButton
  },

  props: {
    planType: { type: String, default: 'common' },
    floorPlans: { type: Array, default: () => ([]) },

    visibleTechRoomList: { type: Boolean, default: false },

    editable: Boolean,
    defectClasses: Boolean,

    addedPoints: { type: Array, default: () => [] },
    updatedPoints: { type: Array, default: () => [] },
    deletedPoints: { type: Array, default: () => [] },

    unsaved: { type: Boolean, default: false },
    comparable: { type: Boolean, default: false },
    contractable: { type: Boolean, default: false }
  },

  data() {
    return {
      getPlanLabel
    }
  },

  computed: {
    ...mapGetters('viewer/plan', [
      'selectedFeature',

      'isPointMode',
      'isRoomMode',
      'isTechRoomMode',
      'isProtocolMode',
      'isPremisesMode',
      'isAcceptanceMode',

      'isRoom',
      'isTechRoom',
      'isCalibration',
      'isLength',
      'isArea',
      'isOrientation',

      'isCamera',
      'isQopter',
      'isFacade',
      'isDefect',
      'isMonitor',

      'isProtocol',
      'isPremises',
      'isIntermediateAcceptance',
      'isFinalAcceptance',

      'isWorkPolygon',

      'isComments',
      'isTransformation',

      'hasSingleSelectedPoint',
      'hasSelectedPhoto',
      'hasSelectedDefect',

      'premisesClassesVisibility'
    ]),
    ...mapGetters('floorPlans', [
      'hasComments'
    ]),
    ...mapGetters('project', [
      'hasTechnicalPlan'
    ]),

    classes() {
      return {
        root: {
          'floor-map-palette abs t-0.75 depth-10 f space-x-0.5 h-2': true,
          'l-11': !this.isTransformation,
          'l-0.75': this.isTransformation
        }
      }
    },

    permissions() {
      return {
        save: {
          [this.planTypeCommon]: ['project_structure_edit'],
          [this.planTypeTech]: ['project_structure_edit'],
          [this.planTypeWork]: ['project_floor_work_plan_be_administrator']
        }[true]
      }
    },

    shouldSave() {
      return this.addedPoints.length 
        || this.updatedPoints.length 
        || this.deletedPoints.length
        || this.unsaved
    },

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    },

    planTypeWork() {
      return this.planType === planTypes.Work
    },

    structureTypeFacades() {
      return this.$route.params.structure === structureTypes.FACADES
    }
  },

  methods: {
    ...mapActions('viewer/plan', [
      'toggleTransformation', 
      'cancelTransformation'
    ]),

    handleCommand(command) {
      this.$emit(command)
    }
  }
}
</script>

<style lang="scss">
.floor-map-palette {
  .el-button {
    color: #FFFFFF;

    &:focus {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      background: #409EFF;
    }
  }
}
</style>
