import { actionable, resourceable } from '../mixins';
import { fromError } from '../../utils/common';

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'projectGroups',
      resetBeforeFetch: true,
      from: ({ api }, { filter, withProjects, withProjectsTracker }) => api.projectGroups.getProjectGroups({ filter, withProjects, withProjectsTracker })
    }),

    actionable({
      name: 'reorderProjectGroups',
      loadable: true,
      at: ({ api }, { groups }) => api.projectGroups.reorderProjectGroups({ groups })
    }),

    actionable({
      name: 'reorderGroupProjects',
      loadable: true,
      at: ({ api }, { group, projects }) => api.projectGroups.reorderGroupProjects({ group, projects })
    }),

    actionable({
      name: 'createProjectGroup',
      loadable: true,
      at: ({ api }, x) => api.projectGroups.createProjectGroup(x).catch(fromError)
    }),

    actionable({
      name: 'updateProjectGroup',
      loadable: true,
      at: ({ api }, x) => api.projectGroups.updateProjectGroup(x).catch(fromError)
    }),

    actionable({
      name: 'removeProjectGroup',
      loadable: true,
      at: ({ api }, x) => api.projectGroups.removeProjectGroup(x)
    })
  ]
}
