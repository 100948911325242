<template>
    <div class="floor-map-control abs l-0.75 t-3.5 f-col space-y-0.25">
        <button-group v-if="!isTransformation"
                      direction="vertical">
            <plan-button icon="plus"
                         tooltip="Увеличить"
                         tooltip-position="right"
                         class="stroke-white"
                         @click="onIn" />
            <plan-button icon="minus"
                         tooltip="Уменьшить"
                         tooltip-position="right"
                         class="stroke-white"
                         @click="onOut" />
        </button-group>

        <!-- Cluster (DISABLED) -->
        <plan-button v-if="false && (planTypeCommon || planTypeTech) && !isTransformation && !isAcceptanceMode"
                     :tooltip="isCluster ? 'Отключить группировку' : 'Включить группировку'"
                     tooltip-position="right"
                     :active="isCluster"
                     icon="cluster"
                     @click="toggleCluster" />

        <!-- Export (DISABLED) -->
        <plan-button v-if="false"
                     tooltip="Экспортировать"
                     tooltip-position="right"
                     icon="print"
                     class="fill-white stroke-none"
                     @click="onExport" />

        <!-- Legend -->
        <plan-button v-if="!isTransformation"
                     tooltip="Легенда"
                     tooltip-position="right"
                     el-icon="el-icon-warning text-white"
                     :active="isLegend"
                     @click="toggleLegend" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { planTypes } from '@/models/plans'

import ButtonGroup from '@/components/map/PlanButton/PlanButtonGroup' 
import PlanButton from '@/components/map/PlanButton/PlanButton'

export default {
  name: 'FloorMapControl',

  components: {
    PlanButton,
    ButtonGroup
  },
  
  props: {
    planType: { type: String, default: 'common' }
  },

  computed: {
    ...mapGetters('viewer/plan', ['isLegend', 'isTransformation', 'isCluster', 'isAcceptanceMode']),

    planTypeCommon() {
      return this.planType === planTypes.Common
    },

    planTypeTech() {
      return this.planType === planTypes.Tech
    }
  },

  methods: {
    ...mapActions('viewer/plan', [
      'toggleLegend',
      'toggleCluster'
    ]),

    onIn: function() {
      this.$emit('in')
    },

    onOut: function() {
      this.$emit('out')
    },

    onLegend: function() {
      this.$emit('legend')
    },

    onExport: function() {
      this.$emit('export')
    }
  }
}
</script>

<style lang="scss">
.floor-map-control {
  .el-button {
    color: #FFFFFF;

    &:focus {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      background: #409EFF;
    }
  }
}
</style>
