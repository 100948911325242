import { planTypes } from '@/models/plans'
import { filterObjectByKeys } from '@/utils/immutable'

export const definePlanFilter = ({ projectFilter, planType = planTypes.Common, mailingId, uiHide } = {}) => filterObjectByKeys({
  withCamera: true,
  withQopter: false,
  withFacade: false,
  withPhoto: true,
  withDefect: true,
  withFinishedDefect: false,
  withPolygons: false,
  withTechRooms: false,

  byDefects: false,
  byFilterPassedOnly: true,
  recognitions_count: projectFilter?.recognitions_count || [],
  with_finished_tasks: true,
  with_finished_not_accepted_tasks: false,

  polygonIds: [],
  workTypes: [],
  withInspector: true,
  withoutInspector: true,
  withWorkType: true,
  withoutWorkType: true,
  withAcceptable: false,
  mailingId
}, [
  ...(planType === planTypes.Common) && [
    'withTechRooms'
  ] || [],
  ...(planType === planTypes.Common || planType === planTypes.Tech) && [
    'withCamera',
    'withQopter',
    'withFacade',
    'withPhoto',
    'withDefect',
    'withFinishedDefecte',
    'withPolygons',

    'byDefects',
    'byFilterPassedOnly',
    'recognitions_count',
    'with_finished_tasks',
    'with_finished_not_accepted_tasks' 
  ] || [],
  ...(planType === planTypes.Work) && [
    'polygonIds',
    'workTypes',
    'withInspector',
    'withoutInspector',
    'withWorkType',
    'withoutWorkType',
    'mailingId'
  ] || []
])
