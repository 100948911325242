<template>
    <portal to="app">
        <draggable class="r-0.75 b-0.75 w-15 h-32 bg-el-dark-0.8 blur rounded-md fs-0.75">
            <template #header>
                <div class="f-x-between f-y-center _fs-0.75 text-white _px-1 _py-0.5 border-bottom-1 border-gray-600">
                    <p class="_m-0">
                        {{ labels.title }}
                    </p>
                    <el-button type="text"
                               class="text-white"
                               icon="el-icon-close"
                               @click="$emit('close')" />
                </div>
            </template>
            <div class="f-col _p-0.5 space-y-0.5 hidden-y">
                <el-input v-model="search"
                          class="w-full"
                          prefix-icon="el-icon-search"
                          clearable
                          :placeholder="labels.search" />
                <div class="_fs-0.75 h-full text-white overflow-auto with-custom-scrollbar-dark">
                    <div v-for="room in filteredTechRooms"
                         :key="room.id"
                         :class="[ 'f-y-center _px-0.5 _py-0.5 hover:bg-gray-600 space-x-0.75', isActive(room.id) ? 'bg-accent-0.3' : ''  ]"
                         @mouseover.self="$emit('hover', room.id)"
                         @mouseleave.self="$emit('hover')"
                         @click.prevent="$emit('hover', room.id, true)">
                        <span class="wh-0.75 no-shrink rounded-sm"
                              :style="{ backgroundColor: getTechRoomColor(room) }" />
                        <span class="grow">
                            {{ getTechRoomName(room) }}
                        </span>
                        <span>{{ room.index }}</span>
                    </div>
                </div>
            </div>
        </draggable>
    </portal>
</template>

<script>
import { resourceable } from '@/store/connectors';
import { getTechRoomName, getTechRoomColor } from '@/models/techRooms';

import Draggable from '@/components/draggable/Draggable';

export default {
  components: { Draggable },
  mixins: [
    resourceable({ on: 'techRooms', name: 'techRooms' })
  ],

  props: {
    planId: { type: String, default: null },
    selectedTechRooms: { type: Array, default: () => [] }
  },

  data() {
    return {
      data: [],
      search: '',

      getTechRoomName,
      getTechRoomColor
    }
  },

  computed: {
    labels() {
      return {
        title: 'Области разметки плана',
        search: 'Поиск помещения...'
      }
    },

    filteredTechRooms() {
      return this.data?.filter(x => !!~getTechRoomName(x).toLowerCase().indexOf(this.search.toLowerCase().trim())) || []
    }
  },

  mounted() {
    this.fetchTechRooms({ planId: this.planId })
      .then((data) => this.data = data.map((x, i) => ({ ...x, index: ++i })))
  },

  methods: {
    isActive(id) {
      return this.selectedTechRooms?.find(x => x.static && x.id === id)
    }
  }
}
</script>
