<template>
    <div :class="classes.root">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'PlanButtonGroup',
  
  props: {
    direction: { type: String, default: 'horizontal' } // vertical
  },

  computed: {
    classes() {
      return {
        root: {
          'plan-button-group': true,
          'f-col rounded overflow-hidden': this.direction === 'vertical',
          'f rounded overflow-hidden': this.direction === 'horizontal'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-button-group {
  .el-button {
    border-radius: 0;
  }
}
</style>