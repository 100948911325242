<template>
    <div class="abs-x-center b-1 w-32 space-y-1">
        <!-- Line -->
        <el-slider v-if="selectedVisionImages.length"
                   v-model="position"
                   class="monitor-point-timeline-slider"
                   :max="selectedVisionImages.length - 1"
                   :format-tooltip="formatTimelinePosition"
                   :marks="marks"
                   input-size="mini"
                   @change="changePosition" />

        <!-- Control -->
        <div class="f-x-between f-y-center">
            <!-- Play -->
            <plan-button :icon="icons.timelinePlaying"
                         :active="played"
                         :label="labels.timelinePlaying"
                         :disabled="visionLoading || !selectedVisionImages.length"
                         @click="toggleTimelinePlaying" />

            <!-- Range -->
            <select-field class="monitor-point-timeline-select-field"
                          :value="range"
                          :options="ranges"
                          :disabled="visionLoading"
                          size="mini"
                          independent
                          @change="changeRange" />
        </div>
    </div>
</template>

<script>
import { startOfDay, subMonths, subWeeks } from 'date-fns'

import { labelAt } from '@/utils/date'
import { defer } from '@/utils/ui'

import { getVisionLastImageShotAt } from '@/models/street-falcon'

import PlanButton from '@/components/map/PlanButton/PlanButton'
import SelectField from '@/components/fields/SelectField.vue'

const labels = {
  playTimeline: 'Запустить просмотр',
  stopTimeline: 'Остановить просмотр',
  timelineDay: 'За сутки',
  timelineWeek: 'За неделю',
  timelineWeekDouble: 'За две недели',
  timelineMonth: 'За месяц'
}

export default {
  components: {
    PlanButton,
    SelectField
  },
  props: {
    selectedVision: { type: Object, default: null },
    selectedVisionImages: { type: Array, default: () => [] },
    visionLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      played: false,
      range: null,
      position: 0
    }
  },
  computed: {
    labels() {
      return {
        ...labels,
        timelinePlaying: this.played ? labels.stopTimeline : labels.playTimeline
      }
    },

    icons() {
      return {
        timelinePlaying: this.played ? 'stop' : 'play'
      }
    },

    ranges() {
      const now = Date.now()
      const from = startOfDay(getVisionLastImageShotAt(this.selectedVision) || now)
      const to = startOfDay(getVisionLastImageShotAt(this.selectedVision) || now)

      return ['timelineDay', 'timelineWeek', 'timelineWeekDouble', 'timelineMonth'].map(x => ({
        value: x,
        label: labels[x],
        ...({
          'timelineDay': { from, to },
          'timelineWeek': { from: subWeeks(now, 1), to },
          'timelineWeekDouble': { from: subWeeks(now, 2), to },
          'timelineMonth': { from: subMonths(now, 1), to }
        })[x]
      }))
    },

    marks() {
      return this.selectedVisionImages.reduce((r, x, i) => ({
        ...r,
        ...x.active_defects_exist && { 
          [i]: {
            style: {
              color: 'red',
              marginTop: '-1.75rem'
            },
            label: this.$createElement('icon', {
              props: {
                name: 'warning',
                color: 'red-1'
              },
              class: 'wh-1',
              on: {
                click: e => {
                  e.stopPropagation()
                  this.changePosition(i)
                }
              }
            })
          }
        }
      }), {})
    }
  },
  mounted() {
    this.range = this.ranges[0]
  },
  methods: {
    changePosition(x) {
      this.position = x
      this.selectedVisionImages[x] 
        && !this.played
        && defer('change-timeline-position', () => this.$emit('change-position', x), 500)
    },

    changeRange(x) {
      this.position = 0
      this.$emit('change-range', this.range = x)
    },

    toggleTimelinePlaying() {
      const x = this.played = !this.played

      const next = () => {
        if (this.position === this.selectedVisionImages.length - 1 || !this.played) {
          this.stopTimelinePlaying()
          return
        }

        this.$emit('change-position-at-playing', this.position = this.position + 1, next)
      }

      x && next()
      !x && this.stopTimelinePlaying()
    },

    stopTimelinePlaying() {
      this.played = false
    },

    formatTimelinePosition(x) {
      return labelAt(this.selectedVisionImages[x]?.created_at, { iso: true, withAlias: false })
    }
  }
}
</script>

<style lang="scss">
.monitor-point-timeline-slider {
  .el-slider__marks-stop {
    background-color: #F56C6C;
    border-radius: unset;
    height: 0.8rem;
    width: 0.25rem;
    margin-top: -0.20rem;
  }
}

.monitor-point-timeline-select-field {
  input {
    background-color: #313131 !important;
    border: none;
    color: white;
  }
}
</style>
