<template>
    <el-card :class="classes.root"
             :shadow="blocked ? 'never' : 'hover'">
        <!-- Overlay -->
        <div v-if="blocked"
             class="as-project-card__blocked card-blocked" />
        <!-- Body -->
        <div :class="classes.body.root"
             @click="click">
            <div class="rel w-full h-14 _p-1 border-box rounded-lg">
                <!-- Image -->
                <el-image :src="image"
                          fit="cover"
                          :class="classes.body.image" />

                <!-- Unblock -->
                <el-tooltip v-if="blocked"
                            class="abs-center depth-10"
                            placement="top"
                            :content="blockReason">
                    <el-button type="primary"
                               @click.stop="clickUnblocked">
                        Разблокировать
                    </el-button>
                </el-tooltip>
                
                <!-- Tags -->
                <div :class="classes.body.tags">
                    <!-- Photo updated at -->
                    <div v-if="labels.photoUpdatedAt"
                         class="h-1.5 _px-0.5 f-y-center space-x-0.5 rounded bg-rock-0.8 blur text-white text-size--supplementary">
                        <i class="el-icon-camera-solid text-white text-size--supplementary" />
                        <span>{{ labels.photoUpdatedAt }}</span>
                    </div>

                    <!-- Structure updated at -->
                    <div v-if="labels.documentUpdatedAt"
                         class="h-1.5 _px-0.5 f-y-center space-x-0.25 rounded bg-rock-0.8 blur text-white text-size--supplementary">
                        <icon name="file-solid"
                              class="fill-white wh-0.75" />
                        <span>{{ labels.documentUpdatedAt }}</span>
                    </div>
                </div>
            </div>

            <div :class="classes.body.description">
                <!-- Title -->
                <is-label :value="title"
                          primary />

                <!-- Address -->
                <el-tooltip v-if="address"
                            :content="address">
                    <div class="text-color--info text-size--supplementary"
                         style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                        <span>Адрес: </span><span>{{ address }}</span>
                    </div>
                </el-tooltip>
            </div>
        </div>

        <!-- Actions -->
        <div v-if="!blocked"
             :class="classes.actions">
            <access :permissions="permissionList['projects.management']"
                    hidable>
                <el-button
                    circle
                    class="card__action-edit"
                    icon="el-icon-edit"
                    type="info"
                    @click.native.stop="clickEdit" />
            </access>
        </div>
    </el-card>
</template>

<script>
import { labelAt } from '@/utils/date';

const labels = {
  blocked: 'Доступ ограничен'
};

export default {
  props: {
    project: {
      type: Object,
      default: null
    }
  },
  computed: {
    classes() {
      return {
        root: {
          'as-project-card rel h-full border--none rounded-lg': true,
          'hover:depth-4': !this.blocked
        },
        body: {
          root: {
            'f-col cursor-pointer w-full hidden-x': true
          },
          image: {
            'w-full h-full rounded-lg': true,
            'opacity-0.5': this.blocked
          },
          tags: {
            'abs b-1.5 l-1.5 f-y-center space-x-0.25': true,
            'opacity-0.5': this.blocked
          },
          description: {
            'space-y-0.4 pdown-1 border-box': true,
            'opacity-0.5': this.blocked
          }
        },
        actions: {
          'abs t-1.5 r-1.5': true,
          'without-events': this.blocked
        }
      }
    },

    labels() {
      return {
        ...labels,
        aboutPhotoUpdatedAt: 'Обновлены фото: ',
        aboutStructureUpdatedAt: 'Обновлена структура: ',
        photoUpdatedAt: labelAt(this.project?.last_defining_point_image_downloaded_at, { withTime: false, iso: true, zoned: false, withAlias: false }),
        documentUpdatedAt: labelAt(this.project?.tracker?.last_task_approval_approved_at, { withTime: false, withAlias: false, iso: true }),
        structureUpdatedAt: labelAt(this.project?.structure_updated_at, { withTime: false, withAlias: false })
      }
    },

    image() {
      return this.project.avatar.storage_thumb_url
    },

    title() {
      return this.project.name
    },

    address() {
      return this.project?.address
    },

    blocked() {
      return this.project.blocked_at;
    },

    blockReason() {
      return this.project.block_reason?.translated_name
    }
  },
  methods: {
    click() {
      !this.blocked && this.$emit('click');
    },

    clickEdit() {
      !this.blocked && this.$emit('click-edit');
    },

    clickUnblocked() {
      this.$emit('click-unblocked');
    }
  }
};
</script>

<style lang="scss" scoped>
.as-project-card {
  position: relative;

  &__blocked, .card-blocked {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
}

</style>
