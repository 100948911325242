<template>
    <div class="abs t-1 r-1 f space-x-0.5">
        <!-- Left -->
        <plan-button 
            v-if="!timelineEnabled"
            icon="corner-left"
            @click="$emit('left')" />

        <!-- Day -->
        <plan-button class="without-events"
                     :label="labels.selectedDate" />

        <!-- Right -->
        <plan-button 
            v-if="!timelineEnabled"
            icon="corner-right"
            @click="$emit('right')" />
    </div>
</template>

<script>
import PlanButton from '@/components/map/PlanButton/PlanButton'
import { formatAsLabel } from '@/utils/date'
import { then } from '@/utils/immutable'

const labels = {

}

export default {
  components: {
    PlanButton
  },
  props: {
    timelineEnabled: { type: Boolean, default: false },
    selectedVisionImagesFrom: { type: Date, default: null },
    selectedVisionImagesTo: { type: Date, default: null }
  },
  computed: {
    labels() {
      return {
        ...labels,
        selectedDate: [
          then(this.selectedVisionImagesFrom, x => formatAsLabel(x, { withCapital: true, withTime: false, withAlias: false, zoned: false })),
          this.timelineEnabled 
            && this.selectedVisionImagesFrom.getTime() !== this.selectedVisionImagesTo.getTime()
            && formatAsLabel(this.selectedVisionImagesTo, { withCapital: true, withTime: false, withAlias: false, zoned: false })
        ].filter(is).join(' - ')
      }
    }
  }
}
</script>
