<template>
    <router-view ref="child"
                 :filter="filter"
                 :filter-for-server="filterForServer"
                 :filter-ready="filterReady"
                 @filter-confirm="confirmFilter"
                 @expanded="collapseNavigator" />
</template>

<script>
export default {
  props: {
    filter: { type: Object, default: () => ({}) },
    filterForServer: { type: Object, default: () => ({}) },
    filterReady: { type: Boolean, default: false }
  },
  methods: {
    confirmFilter(event) {
      this.$emit('filter-confirm', event)
    },

    collapseNavigator(event) {
      this.$emit('expanded', event)
    }
  }
}
</script>