import ProjectPlanEditor from '@/views/projects/PlanEditor'
import MonitorPointViewer from '@/views/plans/MonitorPointViewer.vue'

const PremisesForm = () => import('@/components/forms/PremisesForm')
const PremisesInfoDrawer = () => import('@/components/map/PremisesInfoDrawer')

const routes = structure => [
  {
    name: `project.${structure}.plan`,
    path: 'plan/:planId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    },
    children: [
      {
        name: `project.${structure}.plan.premises.create`,
        path: 'premises/create',
        component: PremisesForm,
        meta: {
          title: 'Редактор плана',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      },
      {
        name: `project.${structure}.plan.premises.info`,
        path: 'premises/info',
        component: PremisesInfoDrawer,
        meta: {
          title: 'Редактор плана',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      },
      {
        name: `project.${structure}.plan.monitoring`,
        path: 'monitoring/:complexId',
        component: MonitorPointViewer,
        meta: {
          title: 'Редактор плана',
          leftSideMenu: {
            on: true,
            name: 'project'
          }
        }
      }
    ]
  },
  {
    name: `project.${structure}.work-plan`,
    path: 'work-plan/:planId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      planType: 'work',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  },
  {
    name: `project.${structure}.plan.rooms`,
    path: 'plan/:planId/rooms/:roomId',
    component: ProjectPlanEditor,
    props: true,
    meta: {
      title: 'Редактор плана',
      leftSideMenu: {
        on: true,
        name: 'project'
      }
    }
  }
];
export default routes;
