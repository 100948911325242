import { STRUCTURE_ITEM_TYPES } from '@/utils/project-structure'

export const structureTypes = {
  PREMISES: 'premises',
  FACADES: 'facades',
  TERRITORY: 'territory'
}

export const houseTypeByStructure = type => type && {
  [structureTypes.PREMISES]: STRUCTURE_ITEM_TYPES.OBJECT,
  [structureTypes.FACADES]: STRUCTURE_ITEM_TYPES.TERRITORY,
  [structureTypes.TERRITORY]: STRUCTURE_ITEM_TYPES.TERRITORY
}[type] || STRUCTURE_ITEM_TYPES.OBJECT