<template>
    <access :allowed="allowed"
            :permissions="permissions"
            :settings="settings"
            hidable>
        <el-tooltip v-if="tooltip"
                    :content="tooltip"
                    :placement="tooltipPosition">
            <template #content>
                <slot name="tooltip" />
            </template>

            <el-button
                :class="classes.button"
                :icon="elIcon"
                :disabled="disabled"
                @click="click">
                <!-- Icon -->
                <icon v-if="icon"
                      :name="icon"
                      :color="colors.icon"
                      class="wh-1" />

                <!-- Label -->
                <span v-if="label" 
                      :class="classes.label">
                    {{ label }}
                </span>

                <slot />
            </el-button>
        </el-tooltip>

        <el-button v-else
                   :class="classes.button"
                   :icon="elIcon"
                   :disabled="disabled"
                   @click="click">
            <!-- Icon -->
            <icon v-if="icon"
                  :name="icon"
                  :color="colors.icon"
                  class="wh-1" />

            <!-- Label -->
            <span v-if="label" 
                  :class="classes.label">
                {{ label }}
            </span>

            <slot />
        </el-button>
    </access>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    tooltip: { type: String, default: null },
    tooltipPosition: { type: String, default: 'bottom' },
    elIcon: { type: String, default: null },
    icon: { type: String, default: null },
    color: { type: String, default: null },
    primary: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    allowed: { type: Boolean, default: true },
    permissions: { type: [Array, String], default: () => [] },
    settings: { type: [Array, String], default: () => [] },
    disabled: { type: Boolean, default: false },
    dark: { type: Boolean, default: true }
  },

  computed: {
    classes() {
      return {
        button: {
          'plan-button f-y-center lh-0.5 m-0 fs-1 rounded button-no-border min-wh-2 _p-0.5': true,
          'bg-accent focus:bg-accent': this.active || this.primary,
          'bg-rock focus:bg-rock': this.dark && !this.active,
          'is_active hover:bg-accent': this.active,
          'hover:bg-gray-800': !this.active && !this.primary && this.dark,
          'hover:bg-gray-200': !this.active && !this.primary && !this.dark,
          'without-events opacity-0.5': this.disabled
        },
        label: {
          'f-y-center fs-0.8 text-white p-0 m-0': true
        }
      } 
    },

    colors() {
      return {
        icon: this.color || (this.dark ? 'gray-100' : 'gray-400')
      }
    }
  },

  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss">
.el-button.plan-button {
  span {
    display: flex;
    align-items: center;
  }
}
</style>
