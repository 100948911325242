<template>
    <div class="projects content">
        <!-- Header -->
        <div class="f-y-center f-x-between _p-2">
            <div class="f-y-center space-x-1">
                <!-- Title -->
                <is-label value="Список проектов"
                          class="grow"
                          text-class="fs-1.5 text-rock" />

                <!-- Region Filter -->
                <select-field v-model="filter.region_ids"
                              :class="classes.header.filter('region').main"
                              :options="regions"
                              value-key="id"
                              label-key="name"
                              placeholder=""
                              as-key
                              multiple
                              clearable
                              independent
                              @change="fetch">
                    <template #prefix>
                        <div :class="classes.header.filter('region').label">
                            <span class="_mr-0.5">Регион</span>
                            <span class="counter">{{ regionsCount }}</span>
                        </div>
                    </template>            
                </select-field>
                
                <!-- Groups Filter -->
                <select-field v-model="filter.id"
                              :class="classes.header.filter('group').main"
                              :options="groups"
                              value-key="id"
                              label-key="name"
                              placeholder=""
                              as-key
                              multiple
                              clearable
                              independent
                              @change="fetch">
                    <template #prefix>
                        <div :class="classes.header.filter('group').label">
                            <span class="_mr-0.5">Группа проектов</span>
                            <span class="counter">{{ groupsCount }}</span>
                        </div>
                    </template>            
                </select-field>  
            </div>

            <!-- Tools -->
            <div class="f space-x-1">
                <!-- Search -->
                <input-field v-model="filter.search"
                             class="w-20"
                             placeholder="Введите название проекта"
                             independent
                             clearable
                             @change="fetchDeferred" />
                <!-- View switcher -->
                <el-button-group>
                    <el-button :class="classes.header.viewSwitcher('grid')"
                               @click="toggleView">
                        <icon name="grid-filled"
                              class="wh-0.75" />
                    </el-button>
                    <el-button :class="classes.header.viewSwitcher('list')"
                               @click="toggleView">
                        <icon name="menu"
                              class="wh-0.75" />
                    </el-button>
                </el-button-group>

                <!-- Add project -->
                <access permissions="system_projects_edit"
                        hidable>
                    <el-button type="primary"
                               size="mini"
                               class="m-0"
                               @click="handleProjectCreate">
                        Добавить проект
                    </el-button>
                </access>
            </div>
        </div>

        <!-- Body -->
        <el-main v-loading="loading"
                 style="overflow: auto"
                 class="with-custom-scrollbar-dark space-y-0.5 _px-2 _pt-0 _pb-2">
            <div v-if="!regionsCount && !groupsCount">
                <!-- Groups -->
                <spoiler v-for="group in projectGroups"
                         :key="group.id"
                         control-align="end"
                         :opened="isOpenedGroup(group)"
                         compact
                         @toggle="x => rememberGroupOpening(group, x)">
                    <!-- Title -->
                    <template #title>
                        <div class="f w-full space-x-1">
                            <is-label :value="group.name"
                                      class="no-shrink"
                                      primary />

                            <el-divider direction="horizontal"
                                        class="_mr-1" />
                        </div>
                    </template>

                    <!-- Body -->
                    <template #default>
                        <!-- Projects as grid -->
                        <el-row v-if="viewAsGrid"
                                :gutter="0"
                                type="flex"
                                class="flex-wrap--wrap _pt-1">
                            <el-col v-for="project in group.projects"
                                    :key="project.id"
                                    :xs="24"
                                    :sm="12"
                                    :md="8"
                                    :lg="6"
                                    class="mb-4">
                                <project-card :project="project"
                                              @click="goToProject(project)"
                                              @click-edit="goToProjectEdit(project)"
                                              @click-unblocked="goToUnblockedForm(project)" />
                            </el-col>
                        </el-row>

                        <!-- Projects as list -->
                        <project-table v-if="viewAsList"
                                       :projects="group.projects"
                                       editable
                                       @project-click="goToProject"
                                       @project-unblock="goToUnblockedForm"
                                       @project-edit="goToProjectEdit" />
                    </template>
                </spoiler>
            </div>
            <div v-else>
                <el-row v-if="viewAsGrid"
                        :gutter="0"
                        type="flex"
                        class="flex-wrap--wrap _pt-1">
                    <el-col v-for="project in projectsWithoutGroups"
                            :key="project.id"
                            :xs="24"
                            :sm="12"
                            :md="8"
                            :lg="6"
                            class="mb-4">
                        <project-card :project="project"
                                      @click="goToProject(project)"
                                      @click-edit="goToProjectEdit(project)"
                                      @click-unblocked="goToUnblockedForm(project)" />
                    </el-col>
                </el-row>

                <!-- Projects as list -->
                <project-table v-if="viewAsList"
                               :projects="projectsWithoutGroups"
                               editable
                               @project-click="goToProject"
                               @project-unblock="goToUnblockedForm"
                               @project-edit="goToProjectEdit" />
            </div>
        </el-main>
    </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'

import { resourceable } from '@/store/connectors'

import ProjectCard from '@/components/projects/ProjectCard'
import ProjectTable from '@/components/projects/ProjectTable'
import InputField from '@/components/fields/InputField'
import SelectField from '@/components/fields/SelectField'
import { equalityById, unique } from '@/utils/immutable'

export default {
  name: 'Projects',
  components: {
    ProjectCard,
    ProjectTable,
    InputField,
    SelectField
  },
  mixins: [
    resourceable({ on: 'projectGroups', name: 'projectGroups' }),
    resourceable({ on: 'projects', name: 'projectsForSelection' }),
    resourceable({ on: 'projects', name: 'regions' })
  ],
  data() {
    return {
      project_dialog: false,
      view: get('projects-view', 'grid', { cookie: true }),

      filter: {
        id: [],
        region_ids: [],
        search: null
      },

      groups: []
    }
  },
  computed: {
    loading() {
      return this.projectGroupsLoading
    },

    classes() {
      return {
        header: {
          filter: field => ({
            main: {
              'w-7': field === 'region',
              'w-11': field === 'group',
              'select-accent': field === 'region' ? this.filter.region_ids.length : this.filter.id.length
            },
            label: {
              'f-v-center _px-1 wh-full text-accent font-bold border-box': true, 
              'text-white': field === 'region' ? this.filter.region_ids.length : this.filter.id.length
            }
          }),
          viewSwitcher: view => ({
            'f-center p-0 wh-2 border--none': true,
            'bg-accent hover:bg-accent focus:bg-accent fill-white': view === this.view,
            'bg-accent-0.1 fill-accent': view !== this.view
          })
        }
      }
    },

    viewAsGrid() {
      return this.view === 'grid'
    },

    viewAsList() {
      return this.view === 'list'
    },

    groupsCount() {
      return this.filter.id.length || ''
    },

    regionsCount() {
      return this.filter.region_ids.length || ''
    },

    projectsWithoutGroups() {
      return unique(this.projectGroups.reduce((arr, x) => [ ...arr, ...x.projects ], []), equalityById)
    }
  },
  mounted() {
    this.fetch({ cache: true, updateGroupList: true })

    if (this.regions.length === 0) this.fetchRegions()
  },
  destroyed() {
    this.clearProjects()
  },
  methods: {
    ...mapMutations({ showForm: 'form/SHOW_FORM' }),
    ...mapActions({
      confirm: 'dialogs/confirmation/confirm',
      projectEdit: 'forms/projectForm/edit'
    }),

    ...mapActions('projects', ['clearProjects']),

    handleProjectCreate() {
      this.showForm({
        formName: 'project-form',
        callback: this.fetch.bind(this, { cache: true, updateGroupList: true })
      });
    },

    fetch({ cache, updateGroupList } = {}) {
      cache && this.fetchProjectsForSelection({ _force: true })
      this.fetchProjectGroups({ filter: this.filter, withProjectsTracker: true }).then(data => { if (updateGroupList) this.groups = data })
    },

    fetchDeferred() {
      defer('fetch-project-groups', this.fetch.bind(this), 500)
    },

    isOpenedGroup(x) {
      return get(`group-${x.id}-opened`, String(x.type !== 'system'), { cookie: true }) === 'true'
    },

    rememberGroupOpening(x, v) {
      set(`group-${x.id}-opened`, v, { cookie: true })
    },

    toggleView() {
      set('projects-view', this.view = ['grid', 'list'].find(x => x !== this.view), { cookie: true })
    },

    goToProject(project) {
      this.$router.push({
        name: 'project',
        params: {
          projectId: project.id
        }
      })
    },

    goToProjectEdit(project) {
      const callback = async () => {
        this.fetch({ cache: true, updateGroupList: true })
      };

      this.showForm({
        formName: 'project-form',
        callback: callback,
        payload: {project_id: project.id}
      });
    },

    goToUnblockedForm(project) {
      this.showForm({
        formName: 'UnblockedRequestForm',
        formTitle: 'Запрос на разблокировку проекта',
        payload: {
          projectId: project.id
        }
      })
    }
  }
};
</script>
<style lang="scss">
.el-drawer__body {
  padding: 20px;
}

.projects.content {
  .el-select__tags {
    display: none;
  }

  .select-accent {
    .el-select {
      .el-input {
        input {
          background-color: #409EFF;
        }
      }

      .counter {
        color: #A0CFFF;
      }
    }
  }
}

.content {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &__header {
    padding: 15px;
    box-shadow: 0 3px 17px 0 #00000017;
    z-index: 10;

    &_inline {
      display: flex;
      justify-content: space-between;
    }

    &__title {
    }

    &__right {
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &-panel {
      display: flex;
      justify-content: space-between;

      .el-cascader {
        width: 250px;

        .el-cascader__tags .el-tag {
          max-width: 65% !important;
        }
      }
    }
  }

  &__body {
    padding: 15px;
    align-self: stretch;
    height: 100%;
    overflow-y: scroll;
    position: relative;

    &_col {
      display: flex;
      flex-direction: column;
    }

    &_row {
      display: flex;
      flex-direction: row;
    }
  }
}

.project-list {
  margin-right: -15px;

  &__link {
    text-decoration: none;
  }

  .project-item {
    width: 25%;
    display: inline-block;
    box-sizing: border-box;
    padding-right: 15px;
    padding-bottom: 15px;
    position: relative;

    &__card {
      border: 3px solid #ffffff;
      background-color: #f3f3f3;

      &:hover {
        border: 3px solid #cdcdce;
      }
    }

    &__create {
      height: 193px;

      .el-card__body {
        background-color: #f9f9f9;
        height: 100%;
      }
    }

    &__name {
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
    }

    &__edit {
      position: absolute;
      right: 20px;
      top: 5px;
    }

    &__image {
      width: 100%;
      height: 135px;
      object-fit: cover;
      display: block;
    }

    &__time {
      font-size: 11px;
      color: #999;
    }
  }
}
</style>
