import Overlay from 'ol/Overlay'

import { fromCollectionError } from '@/utils/common'
import { then } from '@/utils/immutable'
import { removeOverlay } from '@/utils/ui'

import jack from '@/utils/graphics/OpenLayersJack'

export default ({ map, source }) => {
  let width, height
  let offsetWidth, offsetHeight

  let errorsByPoints = {}
  let errorOverlays = []

  return {
    applyBackground({ width: w, height: h, offsetWidth: ow, offsetHeight: oh }) {
      width = w
      height = h
      offsetWidth = ow
      offsetHeight = oh
    },

    disposePoint({ id, name } = {}) {
      then(errorOverlays.find(x => x.get('id') === id || x.get('name') === name), removeOverlay)
    },

    save({ addedPoints, deletedPoints, updatedPoints, doSave, onSaveSuccess, onSaveFail }) {
      const pointsForCreate = addedPoints
        .map(point => ({
          id: point.id,
          type: point.type,
          subtype: point.subtype,
          name: point.name,
          sight_x: point.sight_x,
          sight_y: point.sight_y,
          x_relative: (point.x - offsetWidth) / width,
          y_relative: (point.y - offsetHeight) / height
        }))

      const pointsForDelete = deletedPoints
        .filter(({ id }) => id)

      const pointsForUpdate = updatedPoints
        .map(point => ({
          id: point.id,
          name: point.name,
          subtype: point.subtype,
          sight_x: point.sight_x,
          sight_y: point.sight_y,
          x_relative: (point.x - offsetWidth) / width,
          y_relative: (point.y - offsetHeight) / height
        }))

      errorOverlays.map(removeOverlay)
      errorOverlays = []

      doSave({
        pointsForCreate,
        pointsForDelete,
        pointsForUpdate,

        resolve: () => onSaveSuccess(),

        reject: ({ messages }) => {
          const errors = errorsByPoints = fromCollectionError(messages)
            // compose errors by point(id | name) + error field
            .reduce((r, { index, prefix, postfix, value }) => ({ 
              ...r, 
              ...then(
                (prefix === 'insert.points' && addedPoints[index]?.name) || (prefix === 'update.points' && updatedPoints[index]?.id), 
                x => ({ [x]: { ...r[x], [postfix]: value } })
              ) 
            }), {})

          errorOverlays = Object.entries(errors)
            // find features
            .map(([k, v]) => [jack.by({ source }).find(x => [x.get('id'), x.get('name')].includes(k)), v])
            // filter nullable features
            .filter(([f]) => f)
            // create tooltips
            .map(([f, v]) => {
              const element = document.createElement('div')
              element.className = 'bg-rock text-red-1 _p-0.5 rounded shadow-md fs-0.8'

              // build all errors to single string
              element.innerText = Object.values(v)
                .flat()
                .map(x => '- ' + x)
                .join('\n')

              const tooltip = new Overlay({
                element,
                offset: [0, -16],
                positioning: 'bottom-center',
                stopEvent: false,
                insertFirst: false
              })

              tooltip.setPosition(f.getGeometry().getCoordinates())
              tooltip.set('id', f.get('id'))
              tooltip.set('name', f.get('name'))

              map.addOverlay(tooltip)

              return tooltip
            })

          
          onSaveFail({
            errorsByPoints
          })
        }
      })
    } 
  }
}
