<template>
    <fieldable :label="labels.root"
               :name="name"
               :required="required"
               :errors="errors"
               :no="independent">
        <!-- Select -->
        <el-select v-if="!cascader"
                   ref="select"
                   v-loading="loading"
                   :class="classes.select"
                   popper-class="with-auto-select-height"
                   :value="value"
                   :disabled="disabled"
                   :value-key="asKey ? null : 'id'"
                   :multiple="multiple"
                   :collapse-tags="collapse"
                   :placeholder="placeholder"
                   size="mini"
                   filterable
                   :clearable="clearable"
                   @change="change">
            <!-- Value -->
            <template v-if="native"
                      #prefix>
                <project-thumb :project="value"
                               :address="address"
                               :identifier="identifier"
                               class="w-12 cursor-pointer"
                               :name-color="nameColor"
                               :with-address-tooltip="withAddressTooltipValue"
                               :with-address="withAddress"
                               :with-avatar="withAvatar"
                               :with-identifier="withIdentifier"
                               :dark="dark"
                               compact />
            </template>

            <!-- Option -->
            <el-option v-for="project in projects"
                       :key="project.id"
                       :label="project.name"
                       :value="asKey ? project.id : project"
                       class="_px-0">
                <project-thumb :project="project"
                               :with-address="withAddressOption"
                               :with-address-tooltip="withAddressTooltipOption"
                               :with-avatar="withAvatarOption"
                               :selected="isSelected(project)"
                               :compact="compactOptions" />
            </el-option>
        </el-select>
        <div v-else
             class="rel">
            <div class="f-y-center cursor-pointer"
                 @click="toggleCascaderVisible">
                <project-thumb :project="value"
                               :address="address"
                               :identifier="identifier"
                               class="w-12 cursor-pointer"
                               :name-color="nameColor"
                               :with-address-tooltip="withAddressTooltipValue"
                               :with-address="withAddress"
                               :with-avatar="withAvatar"
                               :with-identifier="withIdentifier"
                               :dark="dark"
                               compact />
                <i class="el-icon-caret-bottom fs-1 text-white" />
            </div>
            <el-cascader ref="cascader"
                         class="abs b-0 l-0 opacity-0 -depth"
                         popper-class="projects-cascader-popover bg-rock-0.8 blur border--none"
                         :value="value?.id"
                         :options="projectsTree"
                         :props="{
                             value: 'id',
                             label: 'name',
                             emitPath: false
                         }"
                         @change="changeCascader" />
        </div>
    </fieldable>
</template>

<script>
import { mapGetters } from 'vuex'
import { resourceable } from '@/store/connectors'
import { injectCommonFieldProps, injectSelectFieldProps } from '@/utils/ui'

import Fieldable from '@/components/fields/Fieldable.vue'
import ProjectThumb from '@/components/projects/ProjectThumb.vue'

const labels = {
  project: 'Проект',
  projects: 'Проекты',
  placeholder: 'Выбрать'
}

export default {
  components: {
    Fieldable,
    ProjectThumb
  },
  mixins: [
    resourceable({ on: 'projects', name: 'projectsTree' }),
    resourceable({ on: 'projects', name: 'projectsForSelection' })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps({
      defaultName: 'project',
      defaultPlaceholder: labels.placeholder
    }),
    ...injectSelectFieldProps(),

    value: { type: [Object, String, Array], default: null },
    address: { type: String, default: null },
    identifier: { type: String, default: null },

    nameColor: { type: String, default: null },

    clearable: { type: Boolean, default: false },

    selectFirst: { type: Boolean, default: false },
    selectCurrent: { type: Boolean, default: false },
    withoutCurrent: { type: Boolean, default: false },

    withAddress: { type: Boolean, default: false },
    withAddressOption: { type: Boolean, default: false },
    withAddressTooltipValue: { type: Boolean, default: false },
    withAddressTooltipOption: { type: Boolean, default: false },
    withAvatar: { type: Boolean, default: false },
    withAvatarOption: { type: Boolean, default: false },
    withIdentifier: { type: Boolean, default: false },

    dark: { type: Boolean, default: false },
    compactOptions: { type: Boolean, default: false },

    collapse: { type: Boolean, default: false },

    native: { type: Boolean, default: false },
    cascader: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('project', ['projectData']),

    classes() {
      return {
        select: {
          'w-full with-restricted-tag-width': true,
          'w-14 without-el-select-input with-full-el-input-height f-y-center h-3': this.native,
          'without-el-select-arrow': this.native && this.disabled
        }
      }
    },

    loading() {
      return this.projectsForSelectionLoading
    },

    labels() {
      return {
        root: this.label || (this.multiple ? labels.projects : labels.project)
      }
    },

    projects() {
      let r = this.items || this.projectsForSelection || []

      r = (this.projectData && this.withoutcurrent) ? r.filter(({ id }) => id !== this.projectData.id) : r

      return r
    }
  },
  watch: {
    projectsForSelection(projects) {
      this.selectFirst && this.change(projects[0])
      this.selectCurrent && this.projectData && this.change(this.asKey ? this.projectData.id : this.projectData)
    },

    items(projects) {
      this.selectFirst && this.change(projects[0])
      this.selectCurrent && this.projectData &&  this.change(this.asKey ? this.projectData.id : this.projectData)
    }
  },
  mounted() {
    this.cascader && this.fetchProjectsTree()
    !this.cascader && this.items === null && this.fetchProjectsForSelection()

    this.selectCurrent 
      && this.projectData 
      && this.change(this.asKey ? this.projectData.id : this.projectData)

    this.selectFirst
      && !this.cascader 
      && this.change(this.projects[0])
  },
  methods: {
    toggleCascaderVisible() {
      this.$refs.cascader.toggleDropDownVisible()
    },

    isSelected(project) {
      return this.asKey
        ? array(this.value).filter(is).some(x => x === project?.id)
        : array(this.value).filter(is).some(x => x.id === project?.id)
    },

    changeCascader() {
      const project = this.$refs.cascader.getCheckedNodes()[0].data.object
      this.change(project)
    },

    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>

<style lang="scss">
.projects-cascader-popover {
  .el-cascader {
    &-menu {
      color: #FFFFFF;

      &:not(:last-child) {
        border-right: 1px solid #303133;
      }
    }

    &-node:not(.is-disabled) {
      &:hover, &:focus {
        background-color: #303133;
      }
    }
  }

  &.el-popper .popper__arrow {
    display: none;
  }
}
</style>